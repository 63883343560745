import { ImageRepository } from '../interfaces/repositories/imageRepository';
import { HttpService } from '../interfaces/services/httpService';
import { HttpResponse } from '../models/httpResponse';

export class ImageHttpRepository implements ImageRepository {
    private httpService: HttpService;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    async getReviewImageIdsBySku(sku: string): Promise<string[]> {
        return await this.httpService
            .get(`inquiry/reviews?sku=${sku}`)
            .then((response: HttpResponse) => {
                return response.status == 200
                    ? (response.data as string[])
                    : [];
            })
            .catch((error) => {
                throw error;
            });
    }

    async createObjectURLByImageId(imageId: string): Promise<string> {
        return await this.httpService
            .getBlob(`inquiry/reviews/${imageId}`)
            .then((blob: Blob) => {                
                return URL.createObjectURL(blob);
            })
            .catch((error) => {
                throw error;
            });
    }
}