import React, { useContext } from 'react';
import {
    OtherBoutikServices,
    OtherBoutikServicesContext,
} from './otherBoutikServicesContext';

export interface IOtherBoutikServicesProviderProps {
    children: React.ReactNode;
}

export const OtherBoutikServicesProvider: React.FC<
    IOtherBoutikServicesProviderProps
> = (props: IOtherBoutikServicesProviderProps) => {
    const otherBoutikServicesContext = useContext(
        OtherBoutikServicesContext
    ) as OtherBoutikServices;

    return (
        <OtherBoutikServicesContext.Provider value={otherBoutikServicesContext}>
            {props.children}
        </OtherBoutikServicesContext.Provider>
    );
};
