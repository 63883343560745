import { ICartContextInternalAPI } from '../CartContext';
import { invalidateCartCheckoutDetails } from './apolloCacheUtils';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';
import { useUpdateCartItemsMutation } from '../graphql';

export const useUpdateCartItemsAction = () => {
    const [
        updateCartItemsMutation,
        updateCartItemsMutationResult,
    ] = useUpdateCartItemsMutation();

    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const updateCartItems = useCallback(
        async (cart_item_id: number, quantity: number) => {
            const cartId = await getCartId();

            // todo find proper way to return loading
            const resp = await updateCartItemsMutation({
                variables: {
                    cart_id: cartId,
                    cart_item_id: cart_item_id,
                    quantity: quantity,
                },
                update: invalidateCartCheckoutDetails,
            });

            return resp;
        },
        [getCartId, updateCartItemsMutation]
    );

    return [updateCartItems, updateCartItemsMutationResult] as const;
};
