import {
    GetCartContentDocument,
    GetCartContentQuery,
    GetCartContentQueryVariables,
} from '../graphql';

import { ApolloCache } from '@apollo/client';
import { Modifier } from '@apollo/client/cache/core/types/common';

const DELETE_FIELD: Modifier<any> = (proxy, { DELETE }) => {
    return DELETE;
};

export const invalidateCartCheckoutDetails = (cache: ApolloCache<any>) => {
    const cart = cache.readQuery<
        GetCartContentQuery,
        GetCartContentQueryVariables
    >({ query: GetCartContentDocument });
    if (!cart?.cart) return;

    cache.modify({
        id: cache.identify(cart.cart),
        fields: {
            shipping_addresses: DELETE_FIELD,
            billing_address: DELETE_FIELD,
            available_payment_methods: DELETE_FIELD,
            selected_payment_method: DELETE_FIELD,
            prices: (proxy) => {
                return {
                    __typename: proxy.__typename,
                    subtotal_excluding_tax: proxy.subtotal_excluding_tax,
                };
            },
        },
    });
};

export const invalidateCartFull = (cache: ApolloCache<any>) => {
    const cart = cache.readQuery<
        GetCartContentQuery,
        GetCartContentQueryVariables
    >({ query: GetCartContentDocument });
    if (cart?.cart)
        cache.evict({
            id: cache.identify(cart.cart),
        });

    cache.evict({
        id: 'customerCart:Cart',
    });
};
