import { graphql, useStaticQuery } from 'gatsby';

import { useMemo } from 'react';
import { useStore } from '../../store';

export function useAllCountries() {
    const { currentStore } = useStore();

    // JWH: Review once we have implemented a better strategy
    const queryResult = useStaticQuery<{
        [key: string]: {
            countries: [
                {
                    id: string;
                    full_name_locale: string;
                    available_regions: [
                        { code: string; id: string; name: string }
                    ];
                }
            ];
        };
    }>(graphql`
        query AllCountries {
            magento_occ_fr_ca {
                countries {
                    id
                    full_name_locale
                    available_regions {
                        code
                        id
                        name
                    }
                }
            }
            magento_occ_en_ca {
                countries {
                    id
                    full_name_locale
                    available_regions {
                        code
                        id
                        name
                    }
                }
            }
            magento_occ_en_us {
                countries {
                    id
                    full_name_locale
                    available_regions {
                        code
                        id
                        name
                    }
                }
            }
        }
    `);

    return useMemo(() => {
        const countries = queryResult[`magento_${currentStore}`].countries;

        const getCountryNameByCode = (countryCode: string) => {
            return countries.find((country) => country.id === countryCode)
                ?.full_name_locale;
        };

        const getCountryCodeByName = (countryName: string) => {
            return countries.find(
                (country) => country.full_name_locale === countryName
            )?.id;
        };

        const getRegionsByCountryName = (countryName: string) => {
            return countries.find(
                (country) => country.full_name_locale === countryName
            )?.available_regions;
        };

        const getRegionObject = (countryName: string, regionName: string) => {
            const regions = countries.find(
                (country) => country.full_name_locale === countryName
            )?.available_regions;
            return regions?.find((region) => region.name === regionName);
        };

        return {
            countries,
            getCountryNameByCode,
            getCountryCodeByName,
            getRegionsByCountryName,
            getRegionObject,
        };
    }, [queryResult, currentStore]);
}
