import { ICartContextInternalAPI } from '../CartContext';
import { useCallback } from 'react';
import { useCartContext } from '../useCartContext';
import { useSetGuestEmailOnCartMutation } from '../graphql';

export const useSetGuestEmailOnCartAction = () => {
    const { getCartId } = useCartContext() as ICartContextInternalAPI;

    const [
        setGuestEmailOnCartMutation,
        setGuestEmailOnCartMutationResult,
    ] = useSetGuestEmailOnCartMutation();

    const setGuestEmailOnCart = useCallback(
        async (guest_email: string) => {
            const cartId = await getCartId();

            const resp = await setGuestEmailOnCartMutation({
                variables: {
                    email: guest_email,
                    cart_id: cartId,
                },
            });

            return resp;
        },
        [getCartId, setGuestEmailOnCartMutation]
    );

    return {
        setGuestEmailOnCart,
        setGuestEmailOnCartMutationResult,
    };
};
