import { Types } from '../../../engine/apis/magento2';
import { useBoutikService } from '../../boutikService';
import { useCallback } from 'react';
import { useUpdateAddressMutation } from '../graphql';

export interface IUpdateAddressInput {
    id: number;

    firstname: string;
    lastname: string;
    company: string;

    street: string[];
    city: string;
    regionCode?: string;
    regionId?: number;
    regionName: string;
    postcode: string;
    countryCode: Types.CountryCodeEnum;

    default_shipping: boolean;
    default_billing: boolean;

    telephone: string;
}

export const useUpdateAddressAction = () => {
    const { assertAuthenticated } = useBoutikService();
    const [
        updateAddressMutation,
        updateAddressMutationResponse,
    ] = useUpdateAddressMutation();

    const updateAddress = useCallback(
        async (address: IUpdateAddressInput) => {
            assertAuthenticated();

            if (address?.regionCode === null || address?.regionCode === '') {
                delete address.regionCode;
                delete address.regionId;
            }

            await updateAddressMutation({
                variables: address,
            });
        },
        [updateAddressMutation, assertAuthenticated]
    );

    return [updateAddress, updateAddressMutationResponse] as const;
};
