import { PricesAndInventoriesCache } from '../../engine/PricesAndInventoriesCache';
import { createContext } from 'react';

type StoreLocale = string;

export interface IStoreContext {
    currentStore: StoreLocale | undefined;
}

export interface IStoreContextInternalAPI extends IStoreContext {
    pricesAndInventoriesCache: PricesAndInventoriesCache;
}

export const StoreContext = createContext<IStoreContext>(
    (undefined as unknown) as IStoreContext
);
