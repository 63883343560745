/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
    APIRestHeaders,
    AuthorizationStatus,
    HttpService,
} from '../interfaces/services/httpService';
import { HttpResponse } from '../models/httpResponse';
import { APIAuthentication } from '../models/apiAuthentication';

export class AxiosService implements HttpService {
    public instance: AxiosInstance;

    private formAuthorization?: APIAuthentication;
    private baseUrl: string;
    private authorizationStatus: AuthorizationStatus;

    constructor(
        baseUrl: string,
        formAuthorization?: APIAuthentication | string
    ) {
        this.baseUrl = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
        this.authorizationStatus = AuthorizationStatus.IDLE;
        this.instance = this.createAxiosInstance();

        if ((formAuthorization as APIAuthentication)?.username) {
            this.formAuthorization = formAuthorization as APIAuthentication;
        } else {
            if (formAuthorization) {
                this.modifyHeader({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${formAuthorization}`,
                } as APIRestHeaders);
                this.authorizationStatus = AuthorizationStatus.ALLOWED;
            } else {
                this.authorizationStatus = AuthorizationStatus.DISABLED;
            }
        }
    }

    private createAxiosInstance(): AxiosInstance {
        return Axios.create({ timeout: 0, baseURL: this.baseUrl });
    }

    private modifyHeader(apiRestHeaders: APIRestHeaders) {
        Object.entries(apiRestHeaders).forEach((entry) => {
            const [key, value] = entry;
            this.instance.defaults.headers[key] = value;
        });
    }

    private async getToken() {
        if (this.authorizationStatus == AuthorizationStatus.IDLE) {
            const response = await this.post(
                'authenticate',
                this.formAuthorization
            ).catch((error) => {
                throw error;
            });

            if (response.status == 200) {
                this.modifyHeader({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${response.data}`,
                } as APIRestHeaders);
                this.authorizationStatus = AuthorizationStatus.ALLOWED;
            } else {
                this.authorizationStatus = AuthorizationStatus.DENIED;
            }
        }
    }

    public async post(url: string, data: any): Promise<HttpResponse> {
        return await this.instance
            .post(url, data)
            .then((response) => {
                return {
                    data: response.data,
                    status: response.status,
                };
            })
            .catch((error) => {
                throw error;
            });
    }

    public async get(url: string): Promise<HttpResponse> {
        let httpResponse = { status: -1, data: null };

        await this.getToken();

        if (
            this.authorizationStatus == AuthorizationStatus.ALLOWED ||
            this.authorizationStatus == AuthorizationStatus.DISABLED
        ) {
            httpResponse = await this.instance.get(url).catch((error) => {
                throw error;
            });
        }

        return Promise.resolve(httpResponse);
    }

    public async getBlob(url: string): Promise<Blob> {
        const config = {
            url: `${this.baseUrl}${url}`,
            method: 'get',
            responseType: 'blob',
        } as AxiosRequestConfig;

        const response = await Axios.request(config);

        return response.data;
    }
}
