/* Created this function because replaceAll seems to be a problem with this version of typescript. Using regex instead */
export function removeWhiteSpaces(str: string) {
    if (!str) return '';
    return str.replace(/ /gi, '');
}

/**
 * Replaces white spaces in a string with a new value
 * @param value The string with white spaces
 * @param newValue The new string value
 * @returns a new value without white spaces
 */
export function replaceWhiteSpace(value: string, newValue: string): string {
    if (!value) return '';
    return value.replace(/ /gi, newValue);
}

export function capitalizeFirstLetter(value: string) {
    if (value) {
        return `${value.charAt(0).toUpperCase()}${value
            ?.slice(1)
            .toLowerCase()}`;
    }
}