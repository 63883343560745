import { CustomerAddressFieldsFragment } from '../graphql/address.fragment.generated';
import { useGetCustomerAddressesQuery } from '../graphql';
import { useMemo } from 'react';

export const useCustomerAddresses = () => {
    const customerAddressesQuery = useGetCustomerAddressesQuery();

    const customerAddresses = useMemo(() => {
        return customerAddressesQuery?.data?.customer?.addresses;
    }, [customerAddressesQuery]);

    const defaultShippingAddress = useMemo(() => {
        return customerAddressesQuery?.data?.customer?.addresses?.find(
            (address) => address!.default_shipping === true
        );
    }, [customerAddressesQuery]);

    const defaultBillingAddress = useMemo(() => {
        return customerAddressesQuery?.data?.customer?.addresses?.find(
            (address) => address!.default_billing === true
        );
    }, [customerAddressesQuery]);

    return [
        { customerAddresses, defaultShippingAddress, defaultBillingAddress },
        customerAddressesQuery,
    ] as const;
};

export const useCustomerAddressById = (id: number) => {
    const [
        { customerAddresses },
        customerAddressesQuery,
    ] = useCustomerAddresses();

    if (typeof id !== 'number') throw new Error('Address ID must be a number');

    const customerAddress = useMemo(() => {
        return customerAddresses?.find(
            (address) => (address as CustomerAddressFieldsFragment).id === id
        );
    }, [id, customerAddresses]);

    return [customerAddress, customerAddressesQuery];
};
